import React, { useEffect } from 'react'
import Image from 'next/image'
import s from './styles.module.css'
import cx from 'classnames'

import Tile1 from './assets/tile1.png'
import Tile2 from './assets/tile2.png'
import Tile3 from './assets/tile3.png'
import Tile4 from './assets/tile4.png'

const InsuranceCoveredLoading = ({ question, goToNextQuestion }) => {
  const header = question?.text || 'Checking Insurance Eligibility'

  useEffect(() => {
    const timer = setTimeout(() => {
      goToNextQuestion()
    }, 3000)

    return () => clearTimeout(timer)
  }, [goToNextQuestion])

  return (
    <div>
      <h2 className={s.header}>{header}</h2>
      <div className={s.animationContainer}>
        <div className={s.row}>
          <div className={cx(s.image, s.image1)}>
            <Image
              src={Tile1}
              alt="Insurance Covered Loading"
              width={85}
              height={85}
            />
          </div>
          <div className={cx(s.image, s.image2)}>
            <Image
              src={Tile2}
              alt="Insurance Covered Loading"
              width={85}
              height={85}
            />
          </div>
        </div>
        <div className={s.row}>
          <div className={cx(s.image, s.image4)}>
            <Image
              src={Tile3}
              alt="Insurance Covered Loading"
              width={85}
              height={85}
            />
          </div>
          <div className={cx(s.image, s.image3)}>
            <Image
              src={Tile4}
              alt="Insurance Covered Loading"
              width={85}
              height={85}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsuranceCoveredLoading
