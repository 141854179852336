export const MATCHES_QUESTION_ID = 'matches'
export const SCHEDULE_QUESTION_ID = 'schedule'
export const PAYMENT_QUESTION_ID = 'payment'
export const CHECKOUT_QUESTION_ID = 'checkout'
export const CLIENT_RECUITER_QUESTION_ID = 'cr'
export const HOW_DID_YOU_HEAR_ABOUT_US_QUESTION_ID = '1570'
export const ADD_LATER_QUESTION_IDS = []
export const LI_QUESTION_SKIPS = [143] // live preference question
export const QUESTIONS_EXTERNAL_VERIFICATION = [102, 106]
export const INTERESTED_IN_NUTRITION_QUESTION_ID = 3
export const LOADING_QUESTION_IDS = [101010]

export const NUTRITION_RESPONSES_VALUE_IDS_TO_HIDE_NUTRITION_OPTIONS = [60, 11]
